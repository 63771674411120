@import 'tailwindcss/base';
@import './prism.css';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

#__next {
	min-height: 100%;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}